<template>
  <div class="batch-add-User-wrapper">
    <div class="top">
      <div class="top-left">
      </div>
      <div class="top-right">
        <v-button @click="addClick" v-if="showBatchAdd">新增</v-button>
      </div>
    </div>

    <div class="content">
       <el-table
        :data="tableData"
        border
        style="width: 100%;"
        height="60vh"
        >

        <el-table-column
          prop="orgId"
          label="*所属组织"
          :min-width="minWidth"
          >
          <template slot-scope="{ row, $index }">
            <select-tree
              :value.sync="row.orgId"
              :key="keyTree"
              :treeData="treeData"
              :treeProps="treeProps"
              :highlightCurrent="true"
              :filterNodeMethod="filterNodeMethod"
              :expand-on-click-node="false"
              class="select-tree-box"
              :width="width"
              :placeholder="customPlacehold($index, '查询组织节点')"
              @nodeClick="nodeClick(row)"
            />
          </template>
        </el-table-column>

        <el-table-column
          prop="userName"
          label="*姓名"
          :min-width="minWidth"
         >
         <template slot-scope="{ row }">
            <v-input v-model="row.userName" placeholder="请输入姓名" />
         </template>
        </el-table-column>

        <el-table-column
          prop="mobileNum"
          label="*手机号"
          :min-width="minWidth"
          >
          <template slot-scope="{ row }">
            <v-input v-model="row.mobileNum" placeholder="请输入手机号" @change="mobileNumChange(row)"/>
          </template>
        </el-table-column>

        <el-table-column
          prop="loginName"
          label="*用户名"
          :min-width="minWidth"
          >
          <template slot-scope="{ row }">
            <v-input v-model="row.loginName" :disabled="!!loginNameDisabled(row)" placeholder="请输入用户名" autocomplete="new-password"/>
          </template>
        </el-table-column>

        <el-table-column
          prop="password"
          label="*密码"
          :min-width="minWidth"
          >
          <template slot-scope="{ row }">
            <v-input style="position:fixed;bottom: -9999px;" />
            <v-input type="password" style="position:fixed;bottom: -9999px;" />
            <v-input type="password" v-model="row.password" placeholder="请输入密码" autocomplete="new-password"/>
          </template>
        </el-table-column>

        <el-table-column
          prop="accountValidDate"
          label="*截止有效期"
          :min-width="minWidth"
          >
          <template slot-scope="{ row }">
          <v-datepicker type="date" format="YYYY-MM-DD"
              v-model="row.accountValidDate"
              :minDate="new Date()"
              @change="validDateChange(row)"
              :width="width"
            />
            <span class="right-span"></span>
            <checkbox-plus type="default" :options="childTypeOps" :value.sync="row.childType" @onChange="(index, value, item) => dateTypeChange(value, row)"></checkbox-plus>
          </template>
        </el-table-column>

        <el-table-column
          prop="userType"
          label="*角色类型"
          :min-width="minWidth"
          >
          <template slot-scope="{ row, $index }">
            <v-select
              v-model="row.userType"
              :options="userTypeFilterOps"
              :width="width"
              :placeholder="customPlacehold($index, '请选择')"
              @change="userTypeChange(row)"
            ></v-select>
          </template>
        </el-table-column>

        <el-table-column
          prop="organizeId"
          label="*管理范围"
          :min-width="minWidth"
          >
          <template slot-scope="{ row, $index }">
           <v-select2
                v-model="row.organizeId"
                :subjoin="roleExtraParams(row)"
                v-bind="organizeParamsComputed(row)"
                :placeholder="customPlacehold($index, '查询管理范围')"
                @onChange="organizeChange"
            ></v-select2>
          </template>
        </el-table-column>

        <el-table-column
          prop="roleId"
          label="*角色权限"
          :min-width="minWidth"
          >
          <template slot-scope="{ row, $index }">
          <v-select2
                v-model="row.roleId"
                :subjoin="rolesExtraParams(row)"
                v-bind="roleParams"
                :placeholder="customPlacehold($index, '查询角色权限')"
              ></v-select2>
          </template>
        </el-table-column>

        <el-table-column
          label="操作"
          >
          <template slot-scope="scope">
            <div class="btn-wrapper">
              <v-button  type="danger" @click="deleteClick(scope)">删除</v-button>
            </div>
          </template>
        </el-table-column>

      </el-table>

      <div class="footer">
        <v-button class="btn" @click="backClick">返回</v-button>
        <v-button type="success" @click="saveClick">保存</v-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mobileRegular } from 'common/regular'
import { SelectTree, CheckboxPlus } from 'components/bussiness'
import { getEnterOrgTreeListURL, getRolesURL, getRoleTypeURL, addRegisterListURL, addEnterpriseUserInfoListURL, getRootRegionIdByOrgIdURL } from './api'
import { organizeParamsFun, organizeExtraParamsFun } from './batchAddHelper'
import moment from 'moment'
export default {
  name: 'batchAddUserList',
  components: {
    SelectTree,
    CheckboxPlus
  },
  computed: {
    roleExtraParams () { // 管理范围
      return organizeExtraParamsFun.bind(this)
    },
    /* 过滤 */
    userTypeFilterOps () {
      // 2 租户组织,账号只能选公司项目, 1 平台职能部门 只能选 集团+系统  3 商家组织 只能选商家门店
      if (Number(this.treeType) === 1) {
        return [...this.userTypeOps.filter(v => v.value === '100' || v.value === '106'), {text: '区域', value: '102'}]
      } else if (Number(this.treeType) === 2) {
        return this.userTypeOps.filter(v => v.value === '101' || v.value === '102')
      } else if (Number(this.treeType) === 3) {
        return this.userTypeOps.filter(v => v.value === '104' || v.value === '105')
      }
      return this.userTypeOps
    },
    organizeParamsComputed () {
      return organizeParamsFun
    },
    loginNameDisabled () { // 登录名禁止修改
      return this.loginNameDisabledFun
    },
  },
  data () {
    return {
      width: 140,
      minWidth: '150px',
      tableData: [],
      treeData: [],
      treeProps: {
        id: 'id', // 必须
        parentId: 'parentId',
        children: 'childOrgList', // 必须
        label: 'orgName', // 必须
        btn: 'operateArr' // 必须
      },
      keyTree: 0,
      childTypeOps: [
        {
          label: '永久',
          value: 1
        }
      ],
      /* select2控件 */
      roleParams: {
        searchUrl: getRolesURL,
        request: {
          text: 'roleName_',
          value: 'roleId_'
        }
      },
      userType: undefined,
      userTypeOps: [],
      defaultData: {
        orgId: '',
        userName: undefined,
        mobileNum: undefined,
        loginName: undefined,
        password: undefined,
        accountValidDate: '',
        userType: undefined,
        organizeId: undefined,
        roleId: undefined,
        childType: undefined,
        companyId: undefined,
      },
      treeType: undefined,
      extraOptions: [
        {
          label: '同上',
          value: '-1',
          obj: {
            label: '同上',
            value: '-1'
          }
        }
      ],
      catchObject: {
        userType: undefined,
        organizeId: undefined,
        roleId: undefined,
        orgId: undefined,
        companyId: undefined,
      },
      defaultCheckedKeys: [],
      treeOrgData: {},
      showBatchAdd: false,
      phoneList: [],  // 提交时待验证的手机号
      loginNameList: [],  // 提交时待验证的用户名
    }
  },
  created () {
    const { treeType } = this.$route.query
    this.treeType = treeType
    this.defaultData.accountValidDate = this.getDefaultDate()
  },
  mounted () {
    this.getTreeList()
    this.getRoleType()
  },
  methods: {
    backClick () {
      this.$router.go(-1)
    },
    async saveClick () {
      // 验证参数
      const info = this.checkParams()
      if (info) {
        this.$message.error(info)
        return
      };
      // 提交表单
      let registerParams = this.registerParamsChange();
      let res = await this.$axios.post(addRegisterListURL, registerParams)
      const { data: ids, status } = res
      if (status !== 100) {
        return
      }

      let addUserParams = this.addUserParamsChange(ids || []);
      let res2 = await this.$axios.post(addEnterpriseUserInfoListURL, addUserParams)
      const { status: status2 } = res2;
      if (status2 == 100) {
        this.$message.success('批量新增成功')
        this.backClick();
      }
    },
    deleteClick (scope) {
      const { row, $index } = scope
      this.tableData.splice($index, 1)
    },
    addClick () {
      // 没有用户信息展示默认值
      let object = new Object()
      if (this.tableData.length > 0) {
        this.resetDefaultData()
      }
      Object.keys(this.defaultData).forEach((key) => {
        object[key] = this.defaultData[key];
      })
      object.__id__ = new Date().getTime() + "_" + Math.random()
      this.tableData.push(object)
    },
    // 设置最新的默认数据
    resetDefaultData () {
      const [item] = this.tableData.slice(-1)
      Object.keys(this.defaultData).forEach((key) => {
        // 姓名手机号密码不修改
        if (['userName', 'mobileNum', 'loginName', 'password'].includes(key)) {
          return
        }
        this.defaultData[key] = item[key]
      })
    },
    //#region 工具
    getDefaultDate () {
      return moment().add(2, 'years').format('YYYY-MM-DD')
      // let towYearTime = new Date().getTime() +  2 * 365 * 24 * 60 * 60 * 1000;
      // return new Date(towYearTime).toLocaleDateString().replace(/\//g, '-') || '';
    },
    userTypeChange (row) {
      row.organizeId = '';
      row.roleId = '';
    },
    organizeChange (row) {
      row.roleId = '';
    },
    checkParams () {
      // 需要验证每一条的必填参数  组织 姓名 手机号 用户名
      // 管理级别 管理范围 角色权限存在依赖关系，都是同上 或 都是新值可用
      let info = false;
      this.phoneList = [];
      this.loginNameList = [];
      for (let index = 0; index < this.tableData.length; index++){
        const item = this.tableData[index];
        this.cascadeCheck(item, index);
        info = this.requireCheck(item, index);
        if (info) {
          return info
        }
      }
      let newPhoneList = this.repeat(this.phoneList);
      if (newPhoneList.length > 0) {
        return `手机号需唯一：${newPhoneList[0]}`
      }
      let newLoginNameList = this.repeat(this.loginNameList);
      if (newLoginNameList.length > 0) {
        return `用户名需唯一：${newLoginNameList[0]}`
      }
      return info;
    },
    requireCheck (item, index) {
      if (['', undefined, null].includes(item.orgId)) {
        return `请选择第${index + 1}条的组织`
      }
      // 手机姓名必填
      if (['', undefined, null].includes(item.userName)) {
        return `请输入第${index + 1}条的姓名`
      }
      if (['', undefined, null].includes(item.mobileNum)) {
        return `请输入第${index + 1}条的手机号`
      }
      if (!mobileRegular.test(item.mobileNum)) {
        return `请正确输入第${index + 1}条的手机号`
      }
      if (!this.loginNameDisabledFun(item) && ['', undefined, null].includes(item.loginName)) {
        return `请正确输入第${index + 1}条的用户名`
      }
      if (['', undefined, null].includes(item.password)) {
        return `请输入第${index + 1}条的用户密码`
      }
      if (['', undefined, null].includes(item.userType)) {
        return `请选择第${index + 1}条的管理级别`
      }
      if (['', undefined, null].includes(item.organizeId)) {
        return `请选择第${index + 1}条的管理范围`
      }
      if (['', undefined, null].includes(item.roleId)) {
        return `请选择第${index + 1}条的角色权限`
      }
      // 保存所有手机号
      this.phoneList.push(item.mobileNum)
      // 保存所有用户名
      this.loginNameList.push(item.loginName)
      // this.changeOrgId(item);
      return false
    },
    repeat (arr) {
      return arr.filter(i => arr.indexOf(i) !== arr.lastIndexOf(i))
    },
    cascadeCheck (item, index) {
      // 管理级别 管理范围 角色权限 都没有或都有可验证通过 必填且有级联关系 当下一级为undefined时 必须保证和上一条是一致的
      const { userType, organizeId, roleId, orgId, companyId } = this.catchObject

      if (['', undefined, null].includes(item.orgId)) {
        item.orgId = orgId;
      }

      if (['', undefined, null].includes(item.companyId)) {
        if (item.orgId === orgId) {
          item.companyId = companyId;
        }
      }

      if (['', null, undefined].includes(item.userType)) {
        if (item.orgId == orgId) {
          item.userType = userType;
          item.organizeId = organizeId;
          item.roleId = roleId;
        }
      }

      if (['', null, undefined].includes(item.organizeId)) {
        if (item.orgId == orgId && item.userType == userType) {
          item.organizeId = organizeId;
          item.roleId = roleId;
        }
      }

      if (['', null, undefined].includes(item.roleId)) {
        if (item.orgId == orgId && item.userType == userType && item.organizeId == organizeId) {
          item.roleId = roleId;
        }
      }

      // 当都有值的时候 默认认为是正确选择的 同时更新最新的缓存值
      this.resetCatch(item);
    },
    resetCatch (item) {
      Object.keys(this.catchObject).forEach((key) => {
        this.catchObject[key] = item[key];
      })
      console.log('Catch', this.catchObject);
    },
    registerParamsChange () {
      let arr = [];
      this.tableData.forEach((item) => {
        arr.push({
          ...item,
          // 第一次请求 orgId 传管理范围
          orgId: item.organizeId,
          userType: +item.userType
        })
      })
      return arr;
    },
    addUserParamsChange (ids) {
      let arr = [];
      this.tableData.forEach((item, index) => {
        if (!ids[index]) {
          return
        }
        arr.push({
          ...item,
          userId: ids[index]
        })
      })
      return arr;
    },
    getRoleType () { // 账号类型 100/101/102...
      this.$axios.get(getRoleTypeURL).then((res) => {
        if (res.status === '100') {
          const { data } = res
          this.userTypeOps = data.map((item) => {
            return {
              text: item.name,
              value: item.id
            }
          })
        }
      })
    },
     /* 选择树控件 */
    filterNodeMethod (value, data, node) { // 过滤树节点的方法
      // value 为 this.filterText 的值, data 为当前节点的数据对象-接口获取
      if (!value) {
        if (node.expanded) {
          node.expanded = false
        }
        return true
      }
      if( data[this.treeProps.label].indexOf(value) !== -1){
        return true
      }else{

        if(node.parent && !Array.isArray(node.parent.data) && node.parent.visible){

          return true
        }else{
          return false
        }
      }
    },
    getTreeList () { // 所属部门树数据
      this.$axios.get(`${getEnterOrgTreeListURL}?orgType=${this.treeType}`)
        .then((res) => {
          if (res.status === 100 && res.msg === 'ok') {
            this.treeData = res.data
            this.keyTree += 1
            const [ { id } ] = this.treeData || [];
            const [{ value }] = this.userTypeFilterOps || [];
            this.defaultData.orgId = id;
            this.defaultData.userType = value;
            this.nodeClick(this.defaultData)
            this.showBatchAdd = true;
          }
        })
    },
    mobileNumChange (row) {
      const { mobileNum } = row;
      if (mobileNum && mobileRegular.test(mobileNum)) {
        row.password = mobileNum.substring(3)
      }else{
        row.password = '';
      }
    },
    validDateChange (row) {
      // 选择时间 请求永久勾选
      row.childType = undefined;
    },
    dateTypeChange (value, row) {
      // 暂时 勾选永久 时间修改为 2099 永久字段暂无意义
      if (value) {
        row.accountValidDate = '2099-01-01';
      }
    },
    organizeChange (val) { // 管理范围
      if (this.oauthShow) { // 如果是授权物业
        if (val && val.id) {
          this.getViewOauthList(val.id)
        } else {
          this.oauthList = []
          this.oauthIds = []
        }
      }
      if (this.oauthStoreShow) { // 如果是授权门店
        if (val && val.id) {
          this.getViewOauthStoreList(val.id)
        } else {
          this.oauthStoreList = []
          this.oauthStoreIds = []
        }
      }
    },
    getViewOauthList (id) { // 其余控件方法 - 管理范围 - 授权物业
      let params = {
        organizeId: id,
        userType: this.userType
      }
      if (this.id) {
        params.userId = this.id
      }
      this.$axios.post(getViewOauthURL, params).then((res) => {
        if (res.status === '100') {
          let oauthList = []
          let ids = []
          res.data && res.data.forEach((item) => {
            if (item.selected || item.name === '集团报事') {
              ids.push(item.id)
            }
            oauthList.push({
              id: item.id,
              name: item.name,
              checked: item.name === '集团报事' ? true : Number(item.selected) === 1
            })
          })
          this.oauthList = oauthList
          this.oauthIds = ids
        }
      })
    },
    getViewOauthStoreList (id) { // 其余控件方法-管理范围-授权门店
      let data = {
        communityId: id,
        userType: this.userType
      }
      this.id && (data.userId = this.id)
      this.$axios.get(getStoreListURL, { params: data }).then((res) => {
        if (res.status === 100) {
          let oauthList = []
          let ids = []
          res.data && res.data.forEach((item) => {
            if (item.selected) {
              ids.push(item.id)
            }
            oauthList.push({
              id: item.id,
              name: item.name,
              checked: Number(item.selected) === 1
            })
          })
          this.oauthStoreList = oauthList
          this.oauthStoreIds = ids
        }
      })
    },
    rolesExtraParams (row) { // 角色
      return {
        roleType: row.userType || '',
        organizeId: row.organizeId || ''
      }
    },
    customTreeData (index) {
      if (index > 0) {
        return [{
        childOrgList: [],
        groupName: "0",
        groupNo: 0,
        id: '-1',
        operateArr: [],
        orgName: "同上",
        parentId: "",
        position: 0,
        tenantId: "",
      }, ...this.treeData]
      }
      return this.treeData
    },
    customPlacehold (index, placeholder) {
      if (index > 0) {
        return '同上'
      }
      return placeholder
    },
    loginNameDisabledFun (item) {
      let arr = [106, 104, 105]
      let status = arr.includes(+item.userType);
      if (status) {
        item.loginName = '';
      }
      return status;
    },

    async nodeClick (row) {
      // 获取选中树节点id  换取最上级id
      const { orgId } = row
      row.companyId = ''
      row.organizeId = ''
      row.roleId = ''
      let res = await this.$axios.get(getRootRegionIdByOrgIdURL, {params: {orgId: orgId}})
      const { data, status } = res;
      if (status == 100) {
        row.companyId = data;
      }
    },
    //#endregion

  }

}
</script>

<style lang="scss" scoped>
.batch-add-User-wrapper{
  overflow: auto;
  .top{
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }
  .content {
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    .select-tree-box ::v-deep .tree-group{
      position: unset;
    }
  }
  .footer{
    display: flex;
    padding: 20px 0;
    .btn{
      margin-right: 20px;
    }
  }
  .btn-wrapper{
    display: flex;
    justify-content: space-around;
  }
}
</style>
