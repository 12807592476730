var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "batch-add-User-wrapper" }, [
    _c("div", { staticClass: "top" }, [
      _c("div", { staticClass: "top-left" }),
      _c(
        "div",
        { staticClass: "top-right" },
        [
          _vm.showBatchAdd
            ? _c("v-button", { on: { click: _vm.addClick } }, [_vm._v("新增")])
            : _vm._e(),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: { data: _vm.tableData, border: "", height: "60vh" },
          },
          [
            _c("el-table-column", {
              attrs: {
                prop: "orgId",
                label: "*所属组织",
                "min-width": _vm.minWidth,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row, $index }) {
                    return [
                      _c("select-tree", {
                        key: _vm.keyTree,
                        staticClass: "select-tree-box",
                        attrs: {
                          value: row.orgId,
                          treeData: _vm.treeData,
                          treeProps: _vm.treeProps,
                          highlightCurrent: true,
                          filterNodeMethod: _vm.filterNodeMethod,
                          "expand-on-click-node": false,
                          width: _vm.width,
                          placeholder: _vm.customPlacehold(
                            $index,
                            "查询组织节点"
                          ),
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(row, "orgId", $event)
                          },
                          nodeClick: function ($event) {
                            return _vm.nodeClick(row)
                          },
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "userName",
                label: "*姓名",
                "min-width": _vm.minWidth,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c("v-input", {
                        attrs: { placeholder: "请输入姓名" },
                        model: {
                          value: row.userName,
                          callback: function ($$v) {
                            _vm.$set(row, "userName", $$v)
                          },
                          expression: "row.userName",
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "mobileNum",
                label: "*手机号",
                "min-width": _vm.minWidth,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c("v-input", {
                        attrs: { placeholder: "请输入手机号" },
                        on: {
                          change: function ($event) {
                            return _vm.mobileNumChange(row)
                          },
                        },
                        model: {
                          value: row.mobileNum,
                          callback: function ($$v) {
                            _vm.$set(row, "mobileNum", $$v)
                          },
                          expression: "row.mobileNum",
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "loginName",
                label: "*用户名",
                "min-width": _vm.minWidth,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c("v-input", {
                        attrs: {
                          disabled: !!_vm.loginNameDisabled(row),
                          placeholder: "请输入用户名",
                          autocomplete: "new-password",
                        },
                        model: {
                          value: row.loginName,
                          callback: function ($$v) {
                            _vm.$set(row, "loginName", $$v)
                          },
                          expression: "row.loginName",
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "password",
                label: "*密码",
                "min-width": _vm.minWidth,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c("v-input", {
                        staticStyle: { position: "fixed", bottom: "-9999px" },
                      }),
                      _c("v-input", {
                        staticStyle: { position: "fixed", bottom: "-9999px" },
                        attrs: { type: "password" },
                      }),
                      _c("v-input", {
                        attrs: {
                          type: "password",
                          placeholder: "请输入密码",
                          autocomplete: "new-password",
                        },
                        model: {
                          value: row.password,
                          callback: function ($$v) {
                            _vm.$set(row, "password", $$v)
                          },
                          expression: "row.password",
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "accountValidDate",
                label: "*截止有效期",
                "min-width": _vm.minWidth,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c("v-datepicker", {
                        attrs: {
                          type: "date",
                          format: "YYYY-MM-DD",
                          minDate: new Date(),
                          width: _vm.width,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.validDateChange(row)
                          },
                        },
                        model: {
                          value: row.accountValidDate,
                          callback: function ($$v) {
                            _vm.$set(row, "accountValidDate", $$v)
                          },
                          expression: "row.accountValidDate",
                        },
                      }),
                      _c("span", { staticClass: "right-span" }),
                      _c("checkbox-plus", {
                        attrs: {
                          type: "default",
                          options: _vm.childTypeOps,
                          value: row.childType,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(row, "childType", $event)
                          },
                          onChange: (index, value, item) =>
                            _vm.dateTypeChange(value, row),
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "userType",
                label: "*角色类型",
                "min-width": _vm.minWidth,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row, $index }) {
                    return [
                      _c("v-select", {
                        attrs: {
                          options: _vm.userTypeFilterOps,
                          width: _vm.width,
                          placeholder: _vm.customPlacehold($index, "请选择"),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.userTypeChange(row)
                          },
                        },
                        model: {
                          value: row.userType,
                          callback: function ($$v) {
                            _vm.$set(row, "userType", $$v)
                          },
                          expression: "row.userType",
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "organizeId",
                label: "*管理范围",
                "min-width": _vm.minWidth,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row, $index }) {
                    return [
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            attrs: {
                              subjoin: _vm.roleExtraParams(row),
                              placeholder: _vm.customPlacehold(
                                $index,
                                "查询管理范围"
                              ),
                            },
                            on: { onChange: _vm.organizeChange },
                            model: {
                              value: row.organizeId,
                              callback: function ($$v) {
                                _vm.$set(row, "organizeId", $$v)
                              },
                              expression: "row.organizeId",
                            },
                          },
                          "v-select2",
                          _vm.organizeParamsComputed(row),
                          false
                        )
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "roleId",
                label: "*角色权限",
                "min-width": _vm.minWidth,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row, $index }) {
                    return [
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            attrs: {
                              subjoin: _vm.rolesExtraParams(row),
                              placeholder: _vm.customPlacehold(
                                $index,
                                "查询角色权限"
                              ),
                            },
                            model: {
                              value: row.roleId,
                              callback: function ($$v) {
                                _vm.$set(row, "roleId", $$v)
                              },
                              expression: "row.roleId",
                            },
                          },
                          "v-select2",
                          _vm.roleParams,
                          false
                        )
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "操作" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "div",
                        { staticClass: "btn-wrapper" },
                        [
                          _c(
                            "v-button",
                            {
                              attrs: { type: "danger" },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteClick(scope)
                                },
                              },
                            },
                            [_vm._v("删除")]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "footer" },
          [
            _c(
              "v-button",
              { staticClass: "btn", on: { click: _vm.backClick } },
              [_vm._v("返回")]
            ),
            _c(
              "v-button",
              { attrs: { type: "success" }, on: { click: _vm.saveClick } },
              [_vm._v("保存")]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }