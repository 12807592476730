/** 管理组织 */
// 获取组织树
const getEnterOrgTreeListURL = `${API_CONFIG.butlerBaseURL}enterpriseOrgInfo/getEnterOrgTreeList`
// 添加组织
const addOrgNodeURL = `${API_CONFIG.butlerBaseURL}enterpriseUserInfo/addOrgNode`
// 移动组织
const updateOrgNodeURL = `${API_CONFIG.butlerBaseURL}enterpriseUserInfo/updateOrgNode`
// 批量移动用户
const updateUserOrgNodesURL = `${API_CONFIG.butlerBaseURL}enterpriseUserInfo/updateUserOrgNodes`
// 批量关闭或开启用户
const batchURL = `${API_CONFIG.butlerBaseURL}manager/change/status`
// 编辑组织
const updateOrgInformationURL = `${API_CONFIG.butlerBaseURL}enterpriseUserInfo/updateOrgInformation`
// 查询组织节点下存在人员信息数量
const selectUserInfoNumByOrgIdURL = `${API_CONFIG.butlerBaseURL}enterpriseUserInfo/selectUserInfoNumByOrgId`
// 逻辑删除组织节点以及节点下存在人员信息
const deleteUserInfoNumByOrgIdURL = `${API_CONFIG.butlerBaseURL}enterpriseUserInfo/deleteUserInfoNumByOrgId`
// 原有接口删除
const deleteURL = `${API_CONFIG.controlBaseURL}tenant/delete/`

/** 成员信息 */
// 查询成员列表
const selectEnterpriseUserInfoListURL = `${API_CONFIG.butlerBaseURL}enterpriseUserInfo/selectEnterpriseUserInfoList`
// 导出成员列表
const exportURL = `${API_CONFIG.butlerBaseURL}enterpriseUserInfo/exportEnterpriseUserInfoList`
// 新增企业组织人员信息
const addEnterpriseUserInfoURL = `${API_CONFIG.butlerBaseURL}enterpriseUserInfo/addEnterpriseUserInfo`
// 编辑企业组织人员信息
const updateEnterpriseUserInfoURL = `${API_CONFIG.butlerBaseURL}enterpriseUserInfo/updateEnterpriseUserInfo`
// 下拉选项数据
const getEnterpriseUserInitCodeWordListURL = `${API_CONFIG.butlerBaseURL}enterpriseUserInfo/getEnterpriseUserInitCodeWordList`
// 人员详情列表
const getEnterpriseUserInfoDetailURL = `${API_CONFIG.butlerBaseURL}enterpriseUserInfo/getEnterpriseUserInfoDetail`
// 上传
const uploadURL = `${API_CONFIG.uploadURL}?module=shopgoods`

/* 老接口 */
// 角色列表
const getOrganizeURL = `${API_CONFIG.baseURL}serverRoleAction!getOrganizeAccount.action`
// 角色归属列表
const getRolesURL = `${API_CONFIG.butlerBaseURL}sysRole/select2Roles`
// 获取角色类型
const getRoleTypeURL = `${API_CONFIG.baseURL}serverUserAction!getRoleType.action`
// 新增
const addManageUserURL = `${API_CONFIG.butlerBaseURL}manager/register`
// 更新
const updateManageUserURL = `${API_CONFIG.butlerBaseURL}manager/update`
// 获取详情
const getUserInfoURL = `${API_CONFIG.baseURL}serverUserAction!view.action`
// 重置密码
const resetPwgURL = `${API_CONFIG.butlerBaseURL}manager/resetPwd`
// 获取授权业务服务列表
const getViewOauthURL = `${API_CONFIG.baseURL}serverUserAction!viewOauth.action`
// 获取授权门店列表
const getStoreListURL = `${API_CONFIG.butlerBaseURL}user/auth/store`
// 获取组织列表
const getOrgRelationListURL = `${API_CONFIG.butlerBaseURL}sysRole/orgRelation`
// 获取组织标签列表
const getDropDownSearchTagURL = `${API_CONFIG.butlerBaseURL}orgTag/getDropDownSearchTag`
// 级联选择器/省、市、区
// const getAreaListURL = `${API_CONFIG.butlerBaseURL}common/getAreaList`
// 账号解锁
const unlockedURL = `${API_CONFIG.baseURL}serverUserAction!unLock.action`
// 物管端App开启待办项配置
const updateTypeURL = `${API_CONFIG.butlerBaseURL}manager/user/notice/updateType`
// 省市区
// const getChinaAreaTreeURL = `${API_CONFIG.butlerBaseURL}common/getAreaList`
// 下拉选项数据
const getEnterpriseUserInitCodeWordListURL1 = `${API_CONFIG.butlerBaseURL}talentUserInfo/getInitCodeWordList`

// 获取员工职责
const getResponsibilityInfoURL = `${API_CONFIG.origin}task-dispatch/dispatch/duty/tree`
// 获取当前员工职责
const getuserDutyListURL = `${API_CONFIG.origin}task-dispatch/dispatch/duty/userDutyList`
// 添加员工职责
const addResponsibility = `${API_CONFIG.origin}task-dispatch/dispatch/duty/add`

// 获取公司或区域管理范围列表
const getListForRegionSelectURL = `${API_CONFIG.butlerBaseURL}newEnterpriseUserInfo/getListForRegionSelect`

// 批量新增用户注册 加入用户表   http://172.16.11.168:7020/manage/
const addRegisterListURL = `${API_CONFIG.butlerBaseURL}manager/registerList`

// 批量新增用户 更改新表
const addEnterpriseUserInfoListURL = `${API_CONFIG.butlerBaseURL}enterpriseUserInfo/addEnterpriseUserInfoList`

// 查询所有组织的列表
const selectEnterpriseUserInfoListNewURL = `${API_CONFIG.butlerBaseURL}newEnterpriseUserInfo/selectEnterpriseUserInfoList`

// 获取项目列表
const queryCommunityListURL = `${API_CONFIG.butlerBaseURL}communityInfo/getAdminCommunityList`

// 根据组织Id 查公司
const getRootRegionIdByOrgIdURL = `${API_CONFIG.butlerBaseURL}newEnterpriseUserInfo/getRootRegionIdByOrgId`

// 列表
const getListURL = `${API_CONFIG.butlerBaseURL}staffManagement/getStaffList`
// 导出
const exportUrl = `${API_CONFIG.butlerBaseURL}staffManagement/exportStaffList`
// 下拉选项数据
const getInitInfoURL = `${API_CONFIG.butlerBaseURL}enterpriseUserInfo/getEnterpriseUserInitCodeWordList`
// 查询薪资标准
const getSalaryStandardByTenantAndGradeURL = `${API_CONFIG.butlerBaseURL}salaryStandard/getSalaryStandardByTenantAndGrade`

// 查询
const queryURL = `${API_CONFIG.shopBaseURL}talentUserInfo/getTalentUserDetail`
// 公司管理-查看页面 头像上传接口
const headUploadURL = `${API_CONFIG.uploadURL}?module=head`
// 员工工作信息统计(新接口) 
const getWorkInfoStatisticsUrl = `${API_CONFIG.butlerBaseURL}newEnterpriseUserInfo/getWorkInfoStatistics`

export {
  uploadURL,
  getEnterOrgTreeListURL,
  addOrgNodeURL,
  updateOrgNodeURL,
  updateUserOrgNodesURL,
  updateOrgInformationURL,
  selectUserInfoNumByOrgIdURL,
  deleteURL,
  deleteUserInfoNumByOrgIdURL,
  addEnterpriseUserInfoURL,
  updateEnterpriseUserInfoURL,
  getEnterpriseUserInitCodeWordListURL,
  getEnterpriseUserInfoDetailURL,
  // getChinaAreaTreeURL,
  selectEnterpriseUserInfoListURL,
  selectEnterpriseUserInfoListNewURL,
  exportURL,

  // 老接口 - accountMgrForm 页面
  batchURL,
  getOrganizeURL,
  getRolesURL,
  getRoleTypeURL,
  addManageUserURL,
  updateManageUserURL,
  getUserInfoURL,
  resetPwgURL,
  getViewOauthURL,
  getStoreListURL,
  getOrgRelationListURL,
  getDropDownSearchTagURL,
  // getAreaListURL,
  unlockedURL,
  updateTypeURL,
  getEnterpriseUserInitCodeWordListURL1,

  // 工单调度接口
  getResponsibilityInfoURL,
  getuserDutyListURL,
  addResponsibility,

  getListForRegionSelectURL,
  addRegisterListURL,
  addEnterpriseUserInfoListURL,
  queryCommunityListURL,
  getRootRegionIdByOrgIdURL,

  getListURL,
  exportUrl,
  getInitInfoURL, 
  getSalaryStandardByTenantAndGradeURL,

  queryURL,
  headUploadURL,
  getWorkInfoStatisticsUrl,
}
